import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { apiUrl } from 'src/app/shared/constants/apiUrl-constants';
import { AlertsPopupComponent } from 'src/app/shared/popups/alerts-popup/alerts-popup.component';
import { HttpService } from 'src/app/shared/services/http.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  dashboardData : any;
  public openNotification: boolean = false;
  userDetails :any;
  constructor(private http: HttpService ,
    private modalService : NgbModal,
    private sharedData : SharedDataService
    ) { }

  ngOnInit() {
    // this.getDashboardData();
    this.getUserDetails()
    this.getAlertsData();
  }
  getUserDetails(){
    this.sharedData.userDetails.subscribe(resdata =>{
      this.userDetails = resdata ;
    })
  }

  // getDashboardData(){
  //   this.http.getApi(apiUrl.DashboardDBasicData).subscribe((resdata:any) =>{
  //     if(resdata.success == true){
  //       this.dashboardData = resdata.result ;
  //     }
  //   })
  // }
  dashboardAlertsData;
  

  onAlertsClick(){
      const dialogRef = this.modalService.open(AlertsPopupComponent, {
        centered: true ,
        size :'xl'
     });
     dialogRef.componentInstance.mode =  1;
     dialogRef.result.then(res =>{
    
    })
  }
  getAlertsData(){
    let body ={
      "pageSize" : 10,
      "pageIndex" : 1
    }
    this.http.postApi(apiUrl.GetDashboardWarnings , body).subscribe((resdata:any) =>{
      if(resdata.success == true){
        this.dashboardAlertsData = resdata.result ;
        // if(this.dashboardAlertsData.totalCount > 0){
        //   this.onAlertsClick();
        // }
      }

      })

    }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
